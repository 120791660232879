textarea {
	height: 200px; }
.contact_form {
	.button {
		background-color: #f8b134;
		margin: 19px auto;
		display: block; }
	.button:hover {
		background-color: #565655; }
	.text-right {
		width: 100%;
		display: block; } }
