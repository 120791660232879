.reference-item {
	background-color: #f7f7f7;
	h4 {
		font-size: 17px;
		font-weight: bolder;
		text-transform: uppercase;
		margin-bottom: 0;
		margin-top: 0.5em;
		a {
			display: block;
			padding: 25px 5px;
			font-weight: 600;
			font-size: 20px;
			color: black;
			@include transition();
			&:hover {
				background-color: $secondaryColor;
				color: $tertiaryColor; } } }
	p {
		color: black !important; } }
.item {
	margin-bottom: 25px;
	border: 2px solid #f8b134; }

.faq-detail {
	margin-bottom: 50px;
	font-family: 'Roboto Condensed'; }

.reference-article {
	margin-bottom: 20px;
	padding: 25px;
	background-color: #f1f6fa;
	.content-article,
	h4 {
		p {
			display: inline-block;
			font-family: 'Roboto Condensed';
			font-size: 16px; } }
	h4 {
		margin-bottom: 0px;
		p {
			margin-bottom: 0;
			font-weight: bold; } } }
