@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

body {
	> footer {
		position: relative;
		//top: -442px
		//height: 442px
		color: $tertiaryColor;
		@media(max-width: 765px) {
			top: 0px;
			height: auto; }
		.map {
			position: absolute;
			top: -2px;
			z-index: 10;
			margin-bottom: -8px;
			@media(max-width: 765px) {
				position: static; } }
		.copyright {
			z-index: 100;
			position: relative;
			background-color: #004888;
			.author {
				padding-top: 15px;
				padding-bottom: 15px;
				font-size: 20px;
				font-weight: 300;
				a {
					color: #05ca00; } } }
		.contact-info {
			//position: relative
			//height: 0px
			//margin-top: -442px
			@media(max-width: 765px) {
				height: auto;
				margin-top: 0;
				.col-xs-12 {
					margin-left: 0 !important;
					padding-left: 0 !important;
					padding-right: 0; } }
			.set-backdrop {
				position: relative;
				z-index: 100;
				height: 440px;
				//top: 440px
				background-color: rgba(35, 115, 186, .85);
				overflow-x: hidden;
				@media(max-width: 991px) {
					&.sm-backdrop {
						padding-left: 60px; } }
				@media(max-width: 765px) {
					height: auto;
					top: 0;
					padding-bottom: 15px; }
				.company-info {
					.contact-form {
						padding-right: 30px;
						@media(max-width: 765px) {
							padding-left: 30px;
							padding-bottom: 15px; }
						.form-group {
							margin-bottom: 5px; }
						input,
						textarea {
							height: 45px;
							font-family: 'Roboto Condensed';
							font-size: 16px;
							font-weight: 300;
							border-radius: 0px;
							border: none;
							box-shadow: none;
							border-left: 3px solid #05ca00;
							background-color: #005eb2;
							color: $tertiaryColor;

 }							//#309dff

						textarea {
							height: 150px; }

						::-webkit-input-placeholder {
							color: white; }
						::-moz-placeholder {
							color: white; }
						:-ms-input-placeholder {
							color: white; }
						:-moz-placeholder {
							color: white; }

						.btn {
							border: none;
							cursor: pointer;
							letter-spacing: 1px;
							position: relative;
							margin-top: 10px;
							padding: 10px 25px 5px;
							border-radius: 0px;
							text-transform: uppercase;
							text-align: left;
							font-size: 30px;
							font-weight: 600;
							z-index: 100;
							background-color: #05ca00;
							@include transition();
							&:after {
								position: absolute;
								bottom: -6px;
								left: 0;
								display: block;
								width: 100%;
								content: '';
								z-index: 10;
								height: 6px;
								background-color: #22ab17; }
							&:hover,
							&:focus {
								top: 3px;
								color: #fff;
								&:after {
									bottom: -3px;
									height: 5px; } } } }

					.heading-contact {
						font-size: 32px;
						text-align: left;
						color: $tertiaryColor;
						@media(max-width: 765px) {
							padding-left: 30px;
							&.visit-us {
								padding-left: 0px; } } }
					.adress-company {
						.adress {
							font-family: 'Roboto Condensed';
							font-size: 18px;
							font-weight: 300;
							&:first-child {
								a {
									color: #1af729; } }
							a {
								color: $tertiaryColor;
								&:hover {
									text-decoration: none; } }

							.fa {
								display: inline-block;
								width: 20px;
								margin-right: 8px;
								text-align: center;
								font-size: 20px;
								color: $tertiaryColor; } } }
					.personally-contact {
						margin-top: 30px;
						margin-right: 45px;
						text-align: left;
						font-family: 'Roboto Condensed';
						a {
							display: block;
							font-family: 'Roboto Condensed';
							font-size: 18px;
							font-weight: 300;
							color: #1af729;
							&:first-child {
								color: $tertiaryColor; }
							&:last-child {
								.fa {
									font-size: 16px; } }
							.fa {
								width: 20px;
								margin-right: 8px;
								text-align: center;
								font-size: 20px;
								color: $tertiaryColor; } } } } } } } }






